import Layout from "../../../components/layout";
import MainContent from "../../../components/maincontent";
import PageBanner from "../../../components/banner/banner";
import React from "react";
import RegisterForm from "../../../components/register/2024/registerform";
import Seo from "../../../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo
      title="Camp Registration"
      description="Online Registration for Charity Grace Bible Camp"
      lang="en"
      meta={[
        {
          content: "God, Jesus, Gospel, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans, Charity Grace Bible Church, Illinois",
          name: "keywords"
        }
      ]}
    />

    <PageBanner
      hasImage={true}
      hasOverlay={false}
      imageUrl="/assets/images/DSC_7115.jpg"
    >
      {/* <p className="banner--text">&quot;All scripture... is profitable for doctrine, for reproof, for correction, for instruction in righteousness...&quot; - II Timothy 3:16</p> */}
    </PageBanner>

    <MainContent
      hasArticle={true}
      hasBanner={true}
    >
      {/* <div className="grid--6"> */}
      <div className="card card--forms">
        <div className="article-width">
          <h1 className='main-header'>Online Registration</h1>
          <RegisterForm />
        </div>
      </div>
      {/* <a className="button button--blue" href="">Paypal</a> */}
      {/* </div> */}

    </MainContent>
  </Layout>
);

export default IndexPage;
